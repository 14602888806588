import ApiService from "@/core/services/api.service";
import Store from "@/core/services/store/index";

/**
 * Service to set UI Brand - Logos, theme and footer.
 */
const BrandService = {

    setUIBrand: function (brand) {
        if(brand) {
            brand = brand.toUpperCase().trim();

            const head = document.head || document.getElementsByTagName('head')[0];

            const brandThemes = [];
            for(const bName in BRAND_INFO) {
                const bInfo = BRAND_INFO[bName];
                if(bInfo.brandTheme) {
                    brandThemes.push(bInfo.brandTheme);
                }
            }

            if(brandThemes.length > 0) {
                // Remove stale theme, if any
                for(const themeName of brandThemes) {
                    const brandCss = document.getElementById(themeName);
                    if(brandCss) {
                        head.removeChild(brandCss);
                    }
                }
            }

            const brandInfo = BRAND_INFO[brand];
            if(brandInfo) {
                Store.commit("setWizardBrandInfo", brandInfo);

                // Set favicon
                if(brandInfo.brandFavicon) {
                    const oldLink = document.getElementById("dynamicFavicon");
                    if (oldLink) {
                        document.head.removeChild(oldLink);
                    }

                    const link = document.createElement("link");
                    link.id = "dynamicFavicon";
                    link.rel = "shortcut icon";
                    link.href = brandInfo.brandFavicon;
                    document.head.appendChild(link);
                }

                // Set brand theme
                const brandThemeName = brandInfo.brandTheme;
                if(!brandThemeName) {
                    return;
                }

                let serverUrl = `${window.location.origin}/services`;
                if(process.env.NODE_ENV === 'development') {
                    serverUrl = `http://localhost:3000/services`;
                }

                ApiService.get(`${serverUrl}/mpf/css/${brandThemeName}`)
                    .then(({ data }) => {
                        const css = data.result;
                        if(css) {
                            const style = document.createElement('style');
                            style.type = 'text/css';
                            style.id = brandThemeName;
                            style.appendChild(document.createTextNode(css));
                            head.appendChild(style);
                        }
                    })
                    .catch((error) => {
                        console.log('Unable to set theme!');
                        if(process.env.NODE_ENV === 'development') {
                            console.log(error);
                        }
                    });
            }
        }
    }
};

const BRAND_INFO = {
    RENTSURE: {
        brandLogo: 'media/logos/Rentsure_Logo.png',
        brandFavicon: 'media/logos/Rentsure_Favicon.png',
        brandFullName: 'Rentsure',
        brandLink: 'https://rentsure.com.au'
    },
    CRI: {
        brandLogo: 'media/logos/CRI_Logo.png',
        brandFavicon: 'media/logos/CRI_Favicon.png',
        brandTheme: 'cri-theme',
        brandFullName: 'Car Rental Insurance',
        brandLink: 'https://crinsurance.com.au'
    },
    FUSE: {
        brandLogo: 'media/logos/Fuse_Logo.png',
        brandFavicon: 'media/logos/Fuse_Favicon.png',
        brandTheme: 'fuse-theme',
        brandFullName: 'Fuse Fleet Insurance',
        brandLink: 'https://fusefleet.com.au'
    }
};

export default BrandService;
